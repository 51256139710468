import React from 'react'
export default class Checkbox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }


    componentDidMount() {

    }

    render() {

        return <div
            className={"input_checkbox_container" + (this.props.value === true ? " checked" : '') + (this.props.disabled === true ? " disabled" : '')}
            onClick={() => this.props.changeValue(!this.props.value)}
        >
            <div className="input_checkbox"></div>
            <div className="input_checkbox_label">{this.props.label}</div>
        </div>
    }

}