import React from 'react'
import { Link } from 'gatsby';

import * as styles from './styles.module.scss'


import ReactHtmlParser from 'react-html-parser';


const Headline = (props) => {

    const returnHeadline = () => {
        
        let extraClasses = '';
        if (props.className) {
            props.className.forEach(className => {

                extraClasses = extraClasses + `${styles[className]} `;

            });

        }



        switch (props.type) {

            case 'h1':
                return <h1 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h1>

            case 'h2':
                return <h2 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h2>

            case 'h3':
                return <h3 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h3>

            case 'h4':
                return <h4 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h4>

            case 'h5':
                return <h5 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h5>

            case 'h6':
                return <h6 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h6>

            default:
                return <h1 style={props.style || {}} className={extraClasses}>{ReactHtmlParser(props.text)}</h1>
        }
    }


    return <>
        {props.subheadline !== undefined && props.subheadline !== '' && <div className={styles.subheadline}>{props.subheadline}</div>}
        {returnHeadline()}
    </>

}


export default Headline;