import React from 'react'
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';
import { animateScroll as scroll } from 'react-scroll'

const Button = (props) => {

    let style = props.style;
    console.log("props", props)


    if (style === undefined) style = [];

    if (style.verticalMargin !== undefined && style.verticalMargin !== '') {
        style.marginTop = style.verticalMargin + 'px';
    }

    if (props.type !== undefined && props.type === 'submit') {

        return <input type="submit" value={props.title} className={`${styles.button} ${styles.primary}`} />

    }

    if (props.onClick !== undefined) {
        return <div onClick={props.onClick} className={`${styles.button} ${styles[props.type]}`} style={style}>
            {props.title}
        </div>
    } else {

        if (props.destination.includes('http')) { // <a></a>
            return <a href={props.destination} style={style} className={`${styles.button} ${styles[props.type]}`}>
                {props.title}
            </a>

        } else if (props.destination.includes('[')) { // do any action

            if (props.destination.includes('play_video_popup')) {
                let link_first = props.destination.split('"')[1];
                let link = link_first.split('"')[0];
            }

        } else if (props.destination.includes('#')) {

            const scrollTo = () => {

                let id = props.destination.replace('#', '');
                console.log("SCROLL TO ", id)
                let object = document.getElementById(id);

                let position = 0;
                if (object !== null) {
                    console.log("SCROLL TO ", object)

                    position = object.offsetTop - 100
                }

                scroll.scrollTo(position)
            }

            return <div onClick={scrollTo} className={`${styles.button} ${styles[props.type]}`} style={style}>
                {props.title}
            </div>


        } else {
            return <Link to={props.destination} className={`${styles.button} ${styles[props.type]}`} style={style}>
                {props.title}
            </Link>
        }

    }

}


export default Button;