import React from 'react'
import { Link } from 'gatsby';





export default class Loading extends React.Component {

    constructor() {
        super();

        this.state = {
            
        }

    }

    componentDidMount() {



    }

    render() {
        return (
            <div className="loading">
                {/* <img src={Animation} /> */}
            </div>
        );
    }

}
