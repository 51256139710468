import { validate } from "./validation";

export const changeValue = (element, key, value) => {
    let formCopy = element.state.form;
    formCopy[key].value = value;

    formCopy[key].valid = validate(value, formCopy[key].rules);

    element.setState({
        form: formCopy
    })
}