import React from 'react'
import Navigation, { social_media_icons } from './Navigation';
import { Link } from 'gatsby';

export default class SocialMediaStickyNavigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }

    render() {

        return <></>;
        
        // let social_media_menu = this.navigation.get_navigation('social-media-menu');

        // return <div className="social_media_sticks_navigation">
        //     <ul>
        //         {social_media_menu.map((item, index) => {

        //             return <li>
        //                 {item.type === 'internal' && item.target === '' ?
        //                     <divLink to={item.slug}><img className="social_media_icon" src={social_media_icons[item.title]} /></divLink>
        //                     :
        //                     <a target={item.target} href={item.slug}><img className="social_media_icon" src={social_media_icons[item.title]} /></a>
        //                 }
        //             </li>

        //         })}
        //     </ul>
        // </div>


    }

}
