import React from 'react'
import { Link } from 'gatsby';
import cookie from 'react-cookies'
import Button from '../Button';
import Checkbox from '../../sections/form/Checkbox';
import { changeValue } from '../../sections/form';
import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_KEY, GOOGLE_TAG_MANAGER_KEY_KEY, LINKEDIN_PIXEL_ID } from '../../../spectory-config';

// import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

export default class CookieNotice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            cookie: cookie.load('cookienotice'),
            form: {
                analytics: {
                    value: false,
                    valid: true,
                    rules: {}
                },
                marketing: {
                    value: false,
                    valid: true,
                    rules: {}
                }
            }
        }

    }

    componentDidMount() {

        this.init();

    }

    cookieNoticeSet = () => {
        return this.state.cookie !== undefined;
    }

    get_id = () => {
        return 'cookienotice'
    }

    showForm = () => {
        this.setState({
            showForm: true
        })
    }

    hideForm = () => {
        this.setState({
            showForm: false
        })
    }

    init = () => {

        if (this.cookieNoticeSet()) {

            if (this.isAllowedAnalytics()) {
                if (GOOGLE_ANALYTICS_KEY !== '') {
                    console.log(GOOGLE_ANALYTICS_KEY)
                    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
                    ReactGA.ga('set', 'anonymizeIp', true);
                    ReactGA.ga('send', 'pageview');
                }

                if (GOOGLE_TAG_MANAGER_KEY_KEY !== '') {
                    const tagManagerArgs = {
                        gtmId: GOOGLE_TAG_MANAGER_KEY_KEY
                    }
                    TagManager.initialize(tagManagerArgs)

                }
            }

            if (this.isAllowedMarketing()) {


                // if(FACEBOOK_PIXEL_ID !== ''){
                //     ReactPixel.init(GOOGLE_ANALYTICS_KEY);
                //     ReactPixel.pageView();
                // }
                // if(LINKEDIN_PIXEL_ID !== ''){
                //     ReactPixel.init(GOOGLE_ANALYTICS_KEY);
                //     ReactPixel.pageView();
                // }

            }

        }

    }

    acceptAllCookies = () => {

        let formCopy = this.state.form;

        formCopy.analytics.value = true;
        formCopy.marketing.value = true;

        this.setState({
            form: formCopy
        });

        this.setCookies();

    }

    acceptSelectedCookies = () => {
        this.setCookies();
    }

    setCookies = () => {

        let acceptedCookies = [];

        let formCopy = this.state.form;

        Object.keys(formCopy).map(key => {

            if (formCopy[key].value === true) {
                acceptedCookies.push(key);
            }

        });

        let expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 100)

        cookie.save(
            'cookienotice',
            JSON.stringify(acceptedCookies),
            {
                path: '/',
                domain: '.' + window.location.hostname,
                expires,
                maxAge: 1000 * 60 * 60 * 24 * 100,
            }
        )

        this.setState({
            cookie: cookie.load('cookienotice')
        })

        this.hideForm();
        this.init();

    }

    isAllowedAnalytics = () => {
        return this.state.cookie.includes('analytics');
    }

    isAllowedMarketing = () => {
        return this.state.cookie.includes('marketing');
    }

    render() {

        if (this.state.showForm) {
            return <div className="cookie_notice_overlay">
                <div className="cookie_notice_container">
                    <h2>Cookies</h2>
                    <p>
                        Wir verwenden Cookies, um die Nutzung auf unserer Website zu verbessern.
                    </p>
                    <br />
                    <Checkbox
                        changeValue={val => changeValue(this, '', val)}
                        label="Notwendige"
                        value={true}
                        disabled
                    />
                    <Checkbox
                        changeValue={val => changeValue(this, 'marketing', val)}
                        label="Marketing"
                        value={this.state.form.marketing.value}
                    />
                    <Checkbox
                        changeValue={val => changeValue(this, 'analytics', val)}
                        label="Analytics"
                        value={this.state.form.analytics.value}
                    />
                    <br />
                    <br />
                    <div className="cookie_notice_buttons_container">
                        <Button onClick={this.acceptAllCookies} type="primary" title="Alle Cookies akzeptieren" />
                        <br />
                        <br />
                        <Button onClick={this.acceptSelectedCookies} type="secondary" title="Ausgewählte Cookies akzeptieren" />
                        <br />
                        <br />
                        <Link to="/datenschutz">Datenschutz</Link>
                    </div>
                </div>

            </div>
        }
        return null;

    }

}
