import React from 'react'
import { Link } from 'gatsby';

import SPOE_Logo from '../../images/logo_spoe.jpg';
import Headline from '../elements/Headline';

import * as styles from './styles/footer.module.scss';

const Footer = () => {

    return (
        <>
            <div className={`container ${styles.footer} `}>
                <div className="col6">
                    <Headline
                        text={"#wirsetzenzeichen"}
                        type={"h1"}
                        className={['red']}
                    />
                    <Headline
                        text={"Für Linz."}
                        type={"h1"}
                    />
                    <Headline
                        text={"Für Dich."}
                        type={"h1"}
                    />
                </div>
                <div className="col3">
                </div>
                <div className="col3">
                    <br />
                    <strong>Kontakt</strong>
                    <br />
                    <br />
                    <a href="mailto:lukas.greul@linzpartei.at">lukas.greul@linzpartei.at</a>
                    <a href="tel:+436767750821">+43 676 7750821</a>
                </div>
            </div>

            <div className={`container ${styles.subFooter}`}>
                <div className="col12">
                    <div className={styles.linkContainer}>
                        <Link to="/datenschutz"><strong>Datenschutz</strong></Link>
                        <Link to="/impressum"><strong>Impressum</strong></Link>
                    </div>

                    <div className={styles.linkContainer}>
                        © 2021 SPÖ Linz - Die Linzpartei - All Rights Reserved
                    </div>

                </div>
            </div>


        </>
    );
}


export default Footer;