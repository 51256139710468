/**
 * Validates form value
 * @param {String} value 
 * @param {Object} rules 
 * 
 * @returns {Boolean}
 */
export const validate = (value, rules) => {

    let valid = true;

    for (let rule in rules) {
        switch (rule) {
            case 'isRequired':
                valid = valid && validateRequired(value)
                break;

            case 'isDouble':
                valid = valid && validateDouble(value)
                break;

            case 'isEmail':
                valid = valid && validateEmail(value)
                break;



            case 'minLength':

                valid = valid && validateMinLength(value, rules[rule])

                break;

            default:
                valid = true
        }
    }

    return valid;

}


/**
 * Validates if value is required
 * @param {String} value 
 */
const validateRequired = value => {
    if (value !== '') {
        return true;
    }
    return false;
}

/**
 * Validates if value is email
 * @param {String} value 
 */
const validateEmail = email => {

    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return expression.test(String(email).toLowerCase());

}

/**
 * Validates if value length is greater or equal
 * @param {String} value 
 * @param {Int} ruleValue 
 */
const validateMinLength = (value, ruleValue) => {

    if (value.length >= ruleValue) {
        return true
    }
    return false

}

/**
 * Validates if value is double
 * @param {String} value 
 */
const validateDouble = value => {


    const regex = /^[0-9]+(,[0-9]+)?$/;

    return regex.test(String(value).toLowerCase());


}

