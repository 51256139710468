import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby';

import * as styles from './styles/header.module.scss';
import * as buttonStyles from '../elements/Button/styles.module.scss';

import Logo from '../../images/logo.png';

import facebook from '../../images/icons/facebook.png';
import instagram from '../../images/icons/instagram.png';
import linkedin from '../../images/icons/linkedin.png';

export const social_media_icons = {
    facebook: facebook,
    instagram: instagram,
    linkedin: linkedin
}

const Navigation = ({ location }) => {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const menuQuery = useStaticQuery(graphql`
        {
            allWpMenu(filter: {locations: {eq: PRIMARY}}) {
                nodes {
                  menuItems {
                    nodes {
                      path
                      label
                    }
                  }
                }
            }
        }
    `)

    const menuItems = menuQuery.allWpMenu.nodes[0].menuItems.nodes

    const closeMenu = (slug) => {

        setMobileOpen(false);

        setTimeout(() => {
            setShowMobileMenu(false)
        }, 300)

    }

    const renderMenuItem = (location, menuItem, index) => {
        return <li
            className={`${location.replace(/\//g, '') === menuItem.path.replace(/\//g, '') ? 'current' : ''} ${(index + 1) === menuItems.length ? 'button' : ''}`}
        >
            <Link
                to={menuItem.path}
                className={` ${(index + 1) === menuItems.length ? buttonStyles.button : ''} ${buttonStyles.primary}`}
            >{menuItem.label}</Link>
        </li>
    }

    const renderNavigation = () => {

        if (menuItems === undefined) return null;
        let logoUsed = false;
        return <>
            <nav className="header_navigation">
                <ul>
                    {menuItems.map((menuItem, index) => {

                        if ((menuItems.length / 2) <= index && !logoUsed) {
                            logoUsed = true;
                            return <>
                                <li>
                                    <Link to={'/'}>
                                        <img
                                            src={Logo}
                                            className={`${styles.logo} ${mobileOpen ? styles.growBig : ''}`}
                                        />
                                    </Link>
                                </li>
                                {renderMenuItem(location, menuItem, index)}
                            </>
                        }

                        return renderMenuItem(location, menuItem, index);

                    })}
                    <li>
                        <Link to={'/'}>
                            <img
                                src={Logo}
                                className={`${styles.logo} ${mobileOpen ? styles.growBig : ''}`}
                            />
                        </Link>
                    </li>
                </ul>
                <Link to={'/'} className={`${styles.logoContainer}`}>
                    <img
                        src={Logo}
                        className={`${styles.logo} ${mobileOpen ? styles.growBig : ''}`}
                    />
                </Link>
                {/* <div
                    onClick={
                        () => {

                            if (mobileOpen) {
                                setMobileOpen(false)

                                setTimeout(() => {
                                    setShowMobileMenu(false)
                                }, 300)
                            } else {
                                setShowMobileMenu(true)

                                setTimeout(() => {
                                    setMobileOpen(true)
                                }, 100)
                            }



                        }
                    }
                    className={"burger" + (mobileOpen || showMobileMenu ? ' active' : '')}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div> */}
            </nav>

            {showMobileMenu &&
                <div className={`menu_overlay ${mobileOpen ? 'active' : ''}`}>
                    <div className="mobile_menu">
                        <ul>
                            {menuItems.map((item, index) => {

                                return <li>
                                    <Link
                                        onClick={closeMenu}
                                        to={item.path}
                                    >{item.label}</Link>
                                </li>

                            })}
                        </ul>
                    </div>
                </div>
            }
        </>

    }

    return (
        <>
            {renderNavigation()}
        </>
    );

}


export default Navigation;