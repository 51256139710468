import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Loading from './sections/Loading';
import Footer from './sections/Footer';
import SocialMediaStickyNavigation from './sections/SocialMediaNavigation';
import CookieNotice from './elements/CookieNotice';

import Transition from '../helpers/Transition';

import "../scss/layout.scss";
import "../scss/form.scss";
import Navigation from "./sections/Navigation";

const Layout = ({ children, lang, path, contentObjects }) => {

    const [initial_loading, setInitial_loading] = useState(true);
    const [transitioning, setTransitioning] = useState(true);
    const [menus, setMenus] = useState([]);
    const cookieNotice = useRef();

    useEffect(() => {

        setInitial_loading(false)
        setTransitioning(false)
        // setMenus(JSON.parse(customAllMenus))

        if (!cookieNotice.current.cookieNoticeSet()) {
            cookieNotice.current.showForm();
        }

        return (() => {
            setTransitioning(true);
        })

    }, []);

    // if (initial_loading) {
    //     return <Loading />;
    // }

    return (
        <>
            
            <Navigation navigation={menus} location={path} />
            <div className={"content_wrapper" + (transitioning ? ' fadeout' : '')}>
                {children}
                <Footer navigation={menus} location={path} />
            </div>
            <SocialMediaStickyNavigation navigation={menus} location={path} />
            <CookieNotice ref={cookieNotice} />
        </>
    );

};

export default Layout;
